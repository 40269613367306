<template>
	<Layout>
		<!-- <PageHeader :title="title"/> -->
		<div class="d-flex justify-content-between align-items-center">
			<h4 class="mb-0 font-size-18 fw-bolder">
				{{
					$route.params.mode == "block"
						? $t(`blocked ip&id history`)
						: $route.params.mode == "login"
						? $t(`login failure history`)
						: $t(`activity logs`)
				}}
			</h4>
		</div>

		<div class="card data">
			<div class="card-header bg-light bg-soft filters">
				<b-form inline>
					<table class="table table-bordered table-nowrap mb-0">
						<tbody>
							<tr>
								<td class="text-center">
									{{ $t("date") }}
								</td>
								<td class="bg-white" style="width: 20%">
									<div class="d-flex gap-1">
										<datepicker
											v-model="filters.from"
											:first-day-of-week="0"
											:locale="$i18n.locale=='ko'?koLocale:null"
											confirm
											class="form-control bg-white"
										></datepicker>

										<label class="input-group-text">~</label>
										<datepicker
											v-model="filters.to"
											:first-day-of-week="0"
											:locale="$i18n.locale=='ko'?koLocale:null"
											confirm
											class="form-control bg-white"
										></datepicker>
									</div>
								</td>

								<td class="text-center" v-if="$route.params.mode != 'block'">
									{{ $t("role") }}
								</td>
								<td
									class="bg-white"
									style="width: 15%"
									v-if="$route.params.mode != 'block'"
								>
									<multiselect
										:placeholder="`${$t('select role')}`"
										v-model="filters.role"
										:options="role_options"
										label="name"
									>
										<template v-slot:singlelabel="{ value }">
											<span class="flex-fill ps-2">{{ $t(value.name) }}</span>
										</template>
										<template v-slot:option="{ option }">
											{{ $t(option.name) }}
										</template>
									</multiselect>
								</td>

								<!-- <td
									class="text-center"
									v-if="
										['player', 'agent'].includes(
											this.filters.role
										)
									">
									{{ $t('username') }}
								</td> 
								<td
									class="bg-white"
									v-if="
										['player', 'agent'].includes(
											this.filters.role
										)
									">
									<input
										type="text"
										class="form-control"
										:placeholder="
											$t(`enter exact username`)
										"
										v-model="filters.username" />
								</td>-->
								<td class="text-center">
									{{ $t("keyword") }}
								</td>
								<td class="bg-white">
									<input
										type="text"
										class="form-control"
										:placeholder="$t(`enter keyword`)"
										v-model="filters.search"
									/>
								</td>
								<td>
									<button
										:class="`btn btn-danger ${loading ? 'disabled' : ''}`"
										@click="resetFilter()"
									>
										{{ $t("reset") }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</b-form>
			</div>
			<div class="card-body">
				<div ref="tableScroller" class="table-responsive">
					<LoadingTable
						cols="8"
						:rows="50"
						:td_small_class="`d-none`"
						class="table-bordered table-striped"
						v-if="loading"
					/>
					<table
						v-else
						class="table table-bordered table-hover table-striped table-nowrap mb-0"
					>
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">
									{{ $t("type") }}
								</th>
								<th scope="col">
									{{ $t("username") }}
								</th>
								<th scope="col">
									{{ $t("referrer") }}
								</th>
								<th scope="col">
									{{ $t("browser") }}
								</th>
								<th scope="col">
									{{ $t("ip address") }}
								</th>
								<th scope="col">
									{{ $t("last access") }}
								</th>

								<th scope="col" v-if="$route.params.mode == 'block'">
									{{ $t("blocked by") }}
								</th>
								<th scope="col" v-if="$route.params.mode == 'login'">
									{{ $t("management") }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(r, i) in data.list" :key="r._id">
								<td class="text-center">
									{{ i + 1 + (data.current_page - 1) * data.per_page }}
								</td>

								<td class="text-center" v-if="$route.params.mode == 'login'">
									{{ r.user ? $t(r.user.role) : "" }}
								</td>
								<td
									v-if="$route.params.mode == 'login'"
									class="text-center"
									:class="{
										'text-muted': r.user == null,
									}"
								>
									<i
										v-if="r.user"
										:class="`mdi ${
											r.user.role == 'agent'
												? 'mdi-account-tie text-danger'
												: r.user.role == 'player'
												? 'mdi-account-cowboy-hat text-primary'
												: ''
										}`"
									></i>
									{{ r.user ? r.user.username : "Unknown User" }}
								</td>

								<td class="text-center" v-if="$route.params.mode == 'block'">
									{{ r.foreign_user ? $t(r.foreign_user.role) : "" }}
								</td>

								<td
									v-if="$route.params.mode == 'block'"
									class="text-center"
									:class="{
										'text-muted': r.foreign_user == null,
									}"
								>
									<i
										v-if="r.foreign_user"
										:class="`mdi ${
											r.foreign_user.role == 'agent'
												? 'mdi-account-tie text-danger'
												: r.foreign_user.role == 'player'
												? 'mdi-account-cowboy-hat text-primary'
												: ''
										}`"
									></i>
									{{
										r.foreign_user ? r.foreign_user.username : "Unknown User"
									}}
								</td>
								<td class="text-center" v-if="$route.params.mode == 'block'">
									<span v-if="r.foreign_user">
										<i v-if="r[`foreign_user`].agent_referrer" class="mdi mdi-account-tie text-danger"></i>
										<i v-if="r[`foreign_user`].player_referrer" class="mdi mdi-account-cowboy-hat text-primary"></i>
										{{
											r[`foreign_user`].agent_referrer
												? r[`foreign_user`].agent_referrer.username
												: r[`foreign_user`].player_referrer
												? r[`foreign_user`].player_referrer.username
												: ""
										}}</span
									>
								</td>
								<td class="text-center" v-if="$route.params.mode == 'login'">
									<span v-if="r.user">
										<i v-if="r[`user`].agent_referrer" class="mdi mdi-account-tie text-danger"></i>
										<i v-if="r[`user`].player_referrer" class="mdi mdi-account-cowboy-hat text-primary"></i>
										{{
											r[`user`].agent_referrer
												? r[`user`].agent_referrer.username
												: r[`user`].player_referrer
												? r[`user`].player_referrer.username
												: ""
										}}
									</span>
								</td>

								<td class="text-center text-truncate" style="max-width: 100px">
									<strong class="mb-0 me-2">{{ r.agent.browser }}</strong>
									<small class="text-muted" :title="r.agent.user_agent">{{r.agent.user_agent}}</small>
								</td>

								<td class="text-center text-primary">
									{{ r.ip }}
								</td>
								<td class="text-center">
									{{ dateYmD(r.created_at, true) }}
								</td>
								<td class="text-center" v-if="$route.params.mode == 'login'">
									<button
										v-if="
											r.user &&
											['agent', 'player'].includes(r.user.role)
										"
										type="button"
										:class="`btn btn-sm ${r.user.status=='active'?'btn-outline-danger':'btn-outline-success'}`"
										@click="blockUser(r)"
									>
										{{ $t(r.user.status=='active'?'block user':'unblock user') }}
									</button>
								</td>
								<td class="text-center" v-if="$route.params.mode == 'block'">
									{{ r.user ? r.user.username : "" }}
								</td>
							</tr>
						</tbody>
					</table>
					<div class="no-data-placeholder" v-if="!data.list.length && !loading">
						{{ $t("no data found") }}
					</div>
				</div>
				<Pagination :data="data" @emitPage="initList" />
			</div>
		</div>
	</Layout>
</template>

<script>
import Datepicker from "vue3-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import LoadingTable from "@/components/loading-table";
import Pagination from "../../../components/pagination.vue";
import Multiselect from "@vueform/multiselect";
import Switches from "vue-switches";
import Swal from "sweetalert2";

import { mapActions, mapGetters } from "vuex";
import random from "../../../mixins/random";
import datetime from "../../../mixins/datetime";
import number from "../../../mixins/number";
import ui from "../../../mixins/ui";
import convert from "../../../mixins/convert";
export default {
	components: {
		Layout,
		PageHeader,
		Pagination,
		Switches,
		LoadingTable,
		Multiselect,
		Datepicker,
	},
	mixins: [datetime, number, random, ui, convert],
	data() {
		return {
			blockHistory:
				this.$route.query.category && this.$route.query.category == "block",
			test: null,
			title: `players`,
			filters: {
				from: new Date(this.firstDateOftheMonth()),
				to: new Date(),
				role: this.$route.query.role ? this.$route.query.role : null,
				function: null,
				status: null,
				table_id: null,
				username: null,
				search: null,
			},
			table_options: [
				{ name: this.$t("table 1"), value: "12314145sdf342412412" },
			],
			role_options: [
				{ name: this.$t("player"), value: "player" },
				{ name: this.$t("agent"), value: "agent" },
				// { name: this.$t("me"), value: "auth" },
			],
			data: {
				list: [],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
				total: 0,
			},
			loading: false,
			hoverDivLeft: 0,
			hoverDivTop: 0,
		};
	},
	watch: {
		filters: {
			async handler() {
				this.initList(1);
			},
			deep: true,
		},
	},
	methods: {
		...mapActions("log", {
			activityGetList: "getList",
		}),
		...mapActions("player", {
			playerUpdate: "update",
		}),
		...mapActions("agent", {
			agentUpdate: "update",
		}),
		async initList(p) {
			this.resetScroll();
			var pl = {
				category: this.$route.params.mode == "block" ? "block" : null,
				role: this.filters.role == null ? "all" : this.filters.role,
				function: this.$route.params.mode == "login" ? "login" : null,
				status: this.$route.params.mode == "login" ? "error" : null,
				log_type: "activity",
				page_no: p ? p : 1,
				from: this.dateYmD(this.filters.from) + " 00:00:00",
				to: this.dateYmD(this.filters.to) + " 23:59:59",
				username: ["player", "agent"].includes(this.filters.role)
					? this.filters.username
					: null,
				search: this.filters.search,
			};
			this.loading = true;
			const data = await this.activityGetList(pl);
			this.data.list = data.data;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
			this.data.total = data.total;
			this.loading = false;
		},
		resetFilter() {
			this.filters.column = null;
			// this.filters.role = null;
			this.filters.username = null;
			this.filters.search = null;
			this.initList(1);
		},
		showHoverDiv(event, id, index) {
			const hoverDiv = document.getElementById(`hover-div-${id}`);
			if (!hoverDiv) return;
			hoverDiv.style.display = "block";
			this.hoverDivLeft = event.pageX;
			this.hoverDivTop = event.pageY;
		},
		hideHoverDiv(id) {
			const hoverDiv = document.getElementById(`hover-div-${id}`);
			if (!hoverDiv) return;
			hoverDiv.style.display = "none";
		},
		async blockUser(r) {
			if (!this.isAuthorized()) return;
			var swal_html = `
						<div>${this.$t(r.user.status=='active'?'block user': 'unblock user')}</div>
						 <table class="table table-sm table-bordered fs-6 mt-3">
							<tbody>
								<tr>
									<th>${this.$t("username")}</th>
									<td>${r.user.username}</td>
								</tr>
								<tr>
									<th>${this.$t("ip address")}</th>
									<td class="text-primary">${r.ip}</td>
								</tr>
							</tbody>
						</table>
					`;
			const confirmed = await Swal.fire({
				title: `${this.$t("are you sure")}?`,
				html: swal_html,
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f1b44c",
				cancelButtonColor: "#74788d",
				cancelButtonText: this.$t("cancel"),
				confirmButtonText: this.$t("confirm"),
			}).then((result) => {
				return result.value;
			});

			if (confirmed) {
				var fd = new FormData();
				fd.append("status", (r.user.status=='active'?'inactive': 'active'));
				const res =
					r.user.role == "player"
						? await this.playerUpdate({
								id: r.user_id,
								form_data: this.formDataToUrlEncoded(fd),
							})
						: await this.agentUpdate({
								id: r.user_id,
								form_data: this.formDataToUrlEncoded(fd),
							});

				if (res) {
					Swal.fire({
						icon: res.success ? "success" : "error",
						title: res.success
							? this.$t(`${r.user.role} has been blocked`)
							: this.$t("blocking failed"),
						showConfirmButton: false,
						timer: 2000,
					});
					if (res.success) {
						this.initList(1);
					}
				}
			}
		},
	},
	mounted() {
		this.initList(1);
		this.$watch(
			() => this.$route.params,
			(n, o) => {
				if (this.$route.name == "activity-logs") {
					this.initList(1);
				}
			}
		);
	},
};
</script>
